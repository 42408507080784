<template>
    <div class="bankDetail-box">
         <!-- 头部 -->
        <div class="head">
            <img src="../assets/img/questionBanner.jpg" alt="">
        </div>
        <div class="content">
            <div class="container">
                <!-- 面包屑和搜索 -->
                <div class="top">
                    <div class="crumbs">
                        <i><img src="../assets/img/loginIcon/index-icon.png" alt=""></i>
                        <a href="javascript:;">首页</a>
                        <p>></p>
                        <a href="javascript:;" class="clickActive">题库中心</a>
                    </div>
                    <div class="searchInput">
                        <el-input
                        placeholder="请输入您感兴趣的课程"
                        clearable
                        v-model="inputValue"
                        @keyup.enter.native="toSearch"
                        >
                            <el-button class="searchBtn"
                                        size="mini"
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="toSearch"
                            ></el-button>
                        </el-input>
                    </div>
                </div>
                <!-- 内容列表 -->
                <div class="listContent">
                    <ul>
                        <li v-for="(item, index) in questionBankDetailList" :key="index">
                            <div class="questionSlide" v-if="item.type ==0">
                                <div class="left">
                                    <div class="icon">
                                        <img src="../assets/img/loginIcon/question-list-icon.png" alt="">
                                    </div>
                                    <div class="text">
                                        <p>{{item.title}}</p>
                                        <p class="sub-text"><span>共{{item.questionCount}}道题</span>/<span>已做{{item.finishQuestionCount}}道题</span></p>
                                    </div>
                                </div>
                                <!-- <div class="middle">
                                    
                                </div> -->
                                <div class="right">
                                    <div class="right-bottom-box" v-if="item.resultId == -1">
                                        <a v-if="item.uuId == 0" @click="jumpPractice(item.obId,item.type,item.id)">
                                            开始做题
                                        </a>
                                        <a v-if="item.uuId != 0" class="buttonActive" @click="jumpPractice(item.obId,item.type,item.id)">
                                            继续做题
                                        </a>
                                    </div>
                                    <div class="right-bottom-box" v-if="item.resultId !=-1">
                                        <a @click="jumpShowQuestion(item.resultId)">
                                            查看详情
                                        </a>
                                        <a v-if="item.uuId == 0" class="buttonActive" @click="jumpPractice(item.obId,item.type,item.id)">
                                            再做一次
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="questionSlide" v-if="item.type ==1">
                                <div class="left">
                                    <div class="icon">
                                        <img src="../assets/img/loginIcon/question-list-icon.png" alt="">
                                    </div>
                                     <div class="text">
                                        <p>{{item.title}}</p>
                                        <p class="sub-text"><span>共{{item.questionCount}}道题</span>/<span>已做{{item.finishQuestionCount}}道题</span></p>
                                    </div>
                                </div>
                                <!-- <div class="middle">
                                    <p>共4道题</p>
                                </div> -->
                                <div class="right">
                                    <div class="right-bottom-box" v-if="item.resultId == -1">
                                        <a v-if="item.uuId == 0" @click="jumpPaper(item.obId,item.type,item.id)">
                                            开始做题
                                        </a>
                                        <a v-if="item.uuId != 0" @click="jumpPaper(item.obId,item.type,item.id)">
                                            继续做题
                                        </a>
                                    </div>
                                    <div class="right-bottom-box" v-if="item.resultId !=-1">
                                        <a :href="'/#/showquestion/'+item.resultId">
                                            查看详情
                                        </a>
                                        <a v-if="item.uuId == 0" @click="jumpPaper(item.obId,item.type,item.id)">
                                            再做一次
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "questionDetail",
    data() {
        return {
            inputValue: '',
            questionBankDetailList: [],
            id:this.$route.params.id,
            // url: '',
            uuId: '',
            obId: '',
            type: '',
            courseContentId: ''
        }
    },
    created() {
        this.getDetailData();
        // this.url='/#/question/?uuId=item.uuid&obId=item.obId'
    },
    metaInfo: {
        title: '题库-题库详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        // 获取搜索
        toSearch() {
            if (this.inputValue == '') return this.$message.error("搜索关键字为空!")
            // this.$store.commit("setSearch", this.inputValue);
            this.getSearch();
        },
        getSearch() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/tiku/practice/list.html',
                data: {
                    courseId: this.id,
                    searchKey: this.inputValue
                }
            }).then((res)=>{
                this.questionBankDetailList = res.data.result.list;
                // this.uuId = res.data.result.list.uuId;
                // console.log(this.uuId)
                for(let i=0;i< this.questionBankDetailList.length;i++) {
                    this.uuId = this.questionBankDetailList[i].uuId;
                    this.obId = this.questionBankDetailList[i].obId;
                    this.type = this.questionBankDetailList[i].type;
                    this.courseContentId = this.questionBankDetailList[i].id;

                }

            })
        },
        // 获取详细
        getDetailData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/tiku/practice/list.html',
                data: {
                    courseId: this.id
                }
            }).then((res)=>{
                this.questionBankDetailList = res.data.result.list;
                // this.uuId = res.data.result.list.uuId;
                // console.log(this.uuId)
                for(let i=0;i< this.questionBankDetailList.length;i++) {
                    this.uuId = this.questionBankDetailList[i].uuId;
                    this.obId = this.questionBankDetailList[i].obId;
                    this.type = this.questionBankDetailList[i].type;
                    this.courseContentId = this.questionBankDetailList[i].id;

                }

            })
        },
        // 跳转练习
        jumpPractice(obId,type,id) {
            this.$router.push({
                name:'practice',
                params: {
                    "obId": obId,
                    "type": type,
                    "courseContentId":id
                }
            })
        },
        // 跳转问题答案详情
        jumpShowQuestion(resultId) {
            this.$router.push({
                name:'showquestion',
                params: {
                    "resultId": resultId,
                }
            })
        },
        // 跳转试卷
        jumpPaper(obId,type,id) {
            this.$router.push({
                name:'paper',
                params: {
                    "obId": obId,
                    "type": type,
                    "courseContentId":id
                }
            })
        }
        // learnNow() {
        //     this.$router.push({
		// 	name: 'paper',
		// 	params:{uuId: this.uuId,obId:this.obId,type:this.type,courseContentId:this.courseContentId }
		// })
        // }
    }
}
</script>
<style lang="scss">
    .bankDetail-box {
        margin-top: 70px;
        width: 100%;
        min-width: 1200px;
        // 头部
        .head {
            width: 100%;
            min-width: 1200px;
            height: 150px;
        }
        // 内容
        .content {
            width: 100%;
            min-width: 1200px;
            padding-top: 60px;
            padding-bottom: 80px;
            box-sizing: border-box;
            background: #EEEEEE;
            .container {
                margin: 0 auto;
                width: 1200px;
                .top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    .crumbs {
                        display: flex;
                        align-items: center;
                        i {
                            display: inline-block;
                            margin-right: 5px;
                            width: 14px;
                            height: 14px;
                        }
                        p {
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            display: inline-block;
                            color: #666666;
                        }
                        .clickActive {
                            color: #FF5A74;
                        }
                    }
                    .searchInput {
                        width: 431px;
                        .el-input__inner {
                            width: 343px;
                            height: 48px;
                            border: 1px solid #FF5A74;
                            border-radius: 5px 0px 0px 5px;
                        }
                        .el-input-group__append {
                            padding: 0 41px;
                            background: #FF5A74;
                            border-radius: 0px 5px 5px 0px;
                            border: 1px solid #FF5A74;
                            .el-icon-search {
                                color: #ffffff;
                            }
                        }
                    }
                }
                .listContent {
                    padding-top: 44px;
                    padding-left: 30px;
                    box-sizing: border-box;
                    margin: 0 auto;
                    width: 1200px;
                    background: white;
                    ul {
                        li {
                            width: 1140px;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            box-sizing: border-box;
                            border-bottom: 1px solid #DEDEDE;
                            .questionSlide {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .left {
                                    display: flex;
                                    width: 900px;
                                    align-items: center;
                                    .icon {
                                        margin-right: 19px;
                                        width: 44px;
                                        height: 44px;
                                    }
                                    .text {
                                        p {
                                            font-size: 16px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #333333;
                                        }
                                        .sub-text {
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #999999;
                                        }
                                    }
                                }
                                .middle {
                                    margin-right: 88px;
                                   
                                    width: 88px;
                                }
                                .right {
                                    .right-bottom-box {
                                        display: flex;
                                        justify-content: space-between;
                                        // width: 232px;
                                        a {
                                            display: inline-block;
                                            width: 100px;
                                            height: 40px;
                                            line-height: 40px;
                                            border: 1px solid #FA4B66;
                                            border-radius: 5px;
                                            text-align: center;
                                            font-size: 16px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #FA4B66;
                                            margin-right: 28px;
                                            cursor: pointer;
                                            &:last-child {
                                                margin-right: 0;
                                            }
                                        }
                                        .buttonActive {
                                            background: #FA4B66;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>